.scroll {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.scroll::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}