
.p-icon {
  color: #fff;
}

.p-dropdown .p-dropdown-trigger {
  background-color: #000;
}

.p-dropdown {
  background-color: #000;
  border: 0px solid #000;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.7);
}
.react-confirm-alert-body {
  background-color: #14161b;
}

.react-confirm-alert-body > h1 {
  color: #fff;
  font-size: 8;
}
.react-confirm-alert-button-group button {
  background-color: #435abd;
}

text {
  color: #000;
}

.loginInputSymbolAdd .p-inputtext {
  background-color: #14161b;
  background: #14161b;
  border-width: 0;
  align-self: center;
  text-align: center;
  width: 150px;
  color: #ffffff;
}

/* Dropdown Button */
.dropbtndelete {
  background-color: #ff3d32;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 7px;
  margin-right: 5px;
  margin-left: 5px;
  z-index: 1px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 1px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #14161b;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1px;
  border-radius: 10px;
  right: 20px;
}

/* Links inside the dropdown */
.dropdown-content div {
  color: #ffffff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  z-index: 1px;
  border-radius: 10px;
  margin: 10px;
}

/* Change color of dropdown links on hover */
.dropdown-content div:hover {
  background-color: #435abd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #435abd;
}
